<template>
   <div class="row flex-wrap justify-content-center">
        <div class="badgeHolder" style="cursor:pointer;" @click="showModal($event,badge)" v-for="(badge,index) in badges"  :key="index">
            <transition name="bounce" >
                <img :src="badge.image">
            </transition>
        </div>
   </div>

   <el-dialog v-model="dialogVisible" :title="choosenBadge && choosenBadge.title" width="30%" style="text-center font-size:24px; font-weight:bold;" draggable>
    	<div class="row justify-content-center">
			<img class="mx-auto" :src="choosenBadge.image">
		</div>
  </el-dialog>
</template>

<script>

import { useStore } from "vuex";
import { computed, onMounted ,onBeforeMount, ref } from "vue";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import VeriTablo from '@/components/tablo/VeriTablo.vue'
import EmptyModal from '@/components/modals/forms/EmptyModal.vue';
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
export default {
    setup() {

        const store = useStore();
        const router = useRouter();
        const brandeklemodal__ref = ref();
		const dialogVisible = ref(false);

		const choosenBadge = ref();
        const badges = computed(() => {
            return store.getters.allBadges;
        });

		const showModal = (event,badge) => {
			dialogVisible.value = true;
			choosenBadge.value = badge;
		}
 
        onMounted(async () =>  {
            store.dispatch(Actions.LOAD_BADGES);
        });


        return {
            badges,
			dialogVisible,
			showModal,
			choosenBadge
        }
    }
}
</script>


<style scoped>
    .badgeHolder{
        width:200px;
    }
    .badgeHolder:hover{
        /* 
        transform: scale(1.1);
        transition:all 0.3 ease-in-out;
         */
        animation: heartBeat 1s infinite;

    }
    .badgeHolder img{
        width:100%;
    }
    .bounce-enter-active {
        animation: bounce-in .5s;
    }
    .bounce-leave-active {
        animation: bounce-in .5s reverse;
    }





    @-webkit-keyframes heartBeat {
	0% {
		-webkit-transform: scale(1);
		transform: scale(1)
	}
	14% {
		-webkit-transform: scale(1.3);
		transform: scale(1.3)
	}
	28% {
		-webkit-transform: scale(1);
		transform: scale(1)
	}
	42% {
		-webkit-transform: scale(1.3);
		transform: scale(1.3)
	}
	70% {
		-webkit-transform: scale(1);
		transform: scale(1)
	}
}

@keyframes heartBeat {
	0% {
		-webkit-transform: scale(1);
		transform: scale(1)
	}
	14% {
		-webkit-transform: scale(1.3);
		transform: scale(1.3)
	}
	28% {
		-webkit-transform: scale(1);
		transform: scale(1)
	}
	42% {
		-webkit-transform: scale(1.3);
		transform: scale(1.3)
	}
	70% {
		-webkit-transform: scale(1);
		transform: scale(1)
	}
}

.animate__heartBeat {
	-webkit-animation-name: heartBeat;
	animation-name: heartBeat;
	-webkit-animation-duration: 1.3s;
	animation-duration: 1.3s;
	-webkit-animation-duration: calc(var(--animate-duration)*1.3);
	animation-duration: calc(var(--animate-duration)*1.3);
	-webkit-animation-timing-function: ease-in-out;
	animation-timing-function: ease-in-out
}
</style>